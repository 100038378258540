/* eslint-disable  */

export default {
  "About us": "Über uns",
  "Notify me" : "Mich vormerken",
  "Account": "Konto",
  "Add new address": "Neue Adresse hinzufügen",
  "Add to compare": "Hinzufügen zum vergleichen",
  "Add to Wishlist": "Zur Wunschliste hinzufügen",
  "Additional Information": "Zusätzliche Information",
  "All Orders": "Alle Bestellungen",
  "Allow order notifications": "Bestellbenachrichtigungen zulassen",
  "Notification when...": "Benachrichtigung bei...",
  "Apply": "Übernehmen",
  "Applied Coupon": "Angewendeter Gutschein",
  "Attention!": "Attention!",
  "Authors": "Autor/-in | Autoren/-innen",
  "Back to home": "Zurück Zur Startseite",
  "Back to homepage": "Zurück zur Homepage",
  "Billing": "Abrechnung",
  "Billing address": "Rechnungsadresse",
  "Billing details":"Rechnungsdetails",
  "Brand": "Marke",
  "Cancel": "Abbrechen",
  "Cart": "Warenkorb",
  "Categories": "Kategorien",
  "Change": "Adresse bearbeiten",
  "Change password your account": "Wenn du dein Passwort ändern möchtest, um auf dein Konto zuzugreifen, gebe folgenden Informationen ein",
  "Change to grid view": "Zur Rasteransicht wechseln",
  "Change to list view": "Zur Listenansicht wechseln",
  "Clear all": "Alles löschen",
  "Color": "Farbe",
  "Commercial information": "und erklären sich damit einverstanden, personalisierte Handelsinformationen vom Markennamen per E-Mail zu erhalten",
  "Contact details updated": "Halte deine Adressen und Kontaktdaten aktuell.",
  "Contact us": "Kontaktiere uns",
  "Continue to billing": "Weiter zur Abrechnung",
  "Continue to payment": "Weiter zur Zahlung",
  "Continue to shipping": "Weiter zum Versand",
  "Cookies Policy": "Cookie-Richtlinie",
  "Create an account.": "Konto erstellen",
  "Create an account": "Konto erstellen",
  "Customer Reviews": "Kundenbewertungen",
  "Customer service": "Kundendienst",
  "Date": "Datum",
  "Default Billing Address": "Standard-Rechnungsadresse",
  "Default Shipping Address": "Standardlieferadresse",
  "Delete": "Löschen",
  "Departments": "Abteilungen",
  "Description": "Beschreibung",
  "Details and status orders": "Hier findest du deine bisherigen Bestellungen",
  "Discount": "Rabatt",
  "Done": "Fertig",
  "Download": "Herunterladen",
  "Download all": "Alle herunterladen",
  "Edit": "Bearbeiten",
  "Email address": "E-Mail Adresse",
  "Empty": "Dein Einkaufskorb ist leer. Beginne deinen Einkauf um Artikel hinzuzufügen",
  "Empty bag": "Leere Tasche",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Gebe deinen Promo-Code ein",
  "Feedback": "Dein Feedback ist uns wichtig. Lasse uns wissen, was wir verbessern können.",
  "Feel free to edit": "Hier kannst du deine persönlichen Daten bearbeiten und deinen Account aktuell halten",
  "Filters": "Filter",
  "Find out more": "Finde mehr heraus",
  "First Name": "Vorname",
  "Forgot Password": "Wenn du das Passwort vergessen hast, kannst du es hier zurücksetzen.",
  "Forgot Password Modal Email": "E-Mail Adresse mit der du bei TISSO registriert bist:",
  "forgotPasswordConfirmation": "Vielen Dank! Wenn ein Konto mit der E-Mail-Adresse {0} registriert ist, findest du in deinem Posteingang eine Nachricht mit einem Link zum Zurücksetzen des Passworts.",
  "Forgotten password?": "Passwort vergessen?",
  "Go back": "Geh zurück",
  "Back":"Zurück",
  "Go back shopping": "Zurück einkaufen",
  "Go back to shop": "Zurück zum Einkaufen",
  "Go to checkout": "Zur Kasse gehen",
  "Guarantee": "Garantie",
  "Help": "Hilfe",
  "Help & FAQs": "Hilfe & FAQs",
  "Health Blog": "Wissen",
  "hide": "ausblenden",
  "Home": "Startseite",
  "I agree to": "Ich stimme zu",
  "I confirm subscription": "Ich bestätige das Abonnement",
  "I want to create an account": "Ich möchte ein Konto erstellen",
  "Info after order": "Du kannst dich in deinen Account einloggen. Mit E-Mail und Passwort, die du zuvor definiert hattest. In deinem account kannst du deine persönlichen Informationen, bisherige Bestellungen sowie dein Abbonement des Newsletters bearbeiten.",
  "Instruction1": "Um mich kümmern",
  "Instruction2": "Nur hier für die Pflegehinweise?",
  "Instruction3": "Ja, das haben wir uns gedacht",
  "It was not possible to request a new password, please check the entered email address.": "Es war nicht möglich, eine neues Passwort anzufordern. Bitte überprüfe die eingegebende E-Mail Adresse.",
  "Item": "Artikel",
  "Items": "Gegenstände",
  "Invoice": "Rechnung",
  "Together strong - partners and cooperations": "Gemeinsam stark - Partner und Kooperationen",
  "Kidswear": "Kinderkleidung",
  "Latest News": "Neueste Beiträge",
  "Last Name": "Nachname",
  "Last Update": "Zuletzt aktualisiert",
  "Let’s start now – we’ll help you": "Fangen wir jetzt an - wir helfen Ihnen.",
  "Log into your account": "In dein Konto einloggen",
  "Login": "Anmelden",
  "Its nice to have you back.": "Schön, dass du wieder da bist!",
  "LoginRegister": "Jetzt anmelden oder registrieren",
  "Access more content": "Zugriff auf mehr Inhalte",
  "login in to your account": "Auf dein Konto anmelden",
  "To access more content, please login with your professional credentials.": "Um auf weitere Inhalte zugreifen zu können, melde dich bitte mit deinen TISSO Zugangsdaten an.",
  "Start shopping to fill it in": {
    "cart1":"Dein Warenkorb ist momentan leer.",
    "cart2":"Hier",
    "cart3":"kannst du ihn füllen!",
    "cartLink":"/de/produkte/"
  },
  "Looks like you haven’t added any items to the Wishlist.": "Sieht so aus, als hättest du noch keine Artikel zur Wunschliste hinzugefügt.",
  "Make an order": "Zahlungspflichtig bestellen",
  "Manage addresses": "Verwalte deine Adressen",
  "Manage billing addresses": "Alle gewünschten Rechnungsadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise musst du die Rechnungsadresse nicht bei jeder Bestellung manuell eingeben.",
  "Manage shipping addresses": "Alle gewünschten Versandadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise musst du die Versandadresse nicht bei jeder Bestellung manuell eingeben.",
  "Match it with": "Kombiniere es mit",
  "Men fashion": "Herrenmode",
  "Menu": "Menü",
  "My billing and shipping address are the same": "Meine Rechnungs- und Lieferadresse sind identisch",
  "My Cart": "Dein Einkaufswagen",
  "My Cart Note": "Neuer Markenauftritt ‒ neues Produktdesign: Einige Produkte liefern wir gerade noch im bisherigen Design aus, um diese nicht einfach entsorgen zu müssen. Nachhaltigkeit liegt uns am Herzen.",
  "New Products": "Neue Produkte",
  "No account": "Du hast noch keinen Account?",
  "or": "oder",
  "or fill the details below": "oder fülle die Details unten",
  "Order ID": "Bestellung ID",
  "Order information": "Bestellinformationen",
  "Order No.": "Bestellnummer",
  "Other products you might like": "Andere Produkte, die Ihnen gefallen könnten",
  "Out of stock": "Produkt nicht verfügbar",
  "Password": "Passwort",
  "Password Changed": "Passwort erfolgreich geändert. Du kannst zur Startseite zurückkehren und dich anmelden",
  "Pay for order": "Für Bestellung bezahlen",
  "Payment": "Zahlung",
  "Payment & Delivery": "Zahlung & Lieferung",
  "Payment Method": "Zahlungsmethode",
  "Payment Methods": "Zahlungsmethoden",
  "Payment available": "Angebotene Zahlarten",
  "Personal details": "Persönliche Daten",
  "Please type your current password to change your email address.": "Bitte gib dein aktuelles Passwort ein, um deine E-Mail-Adresse zu ändern.",
  "Price": "Preis",
  "Privacy": "Datenschutz",
  "Privacy Policy": "Datenschutzrichtlinie",
  "Product": "Produkt",
  "Product description": "Das Karissa V-Neck Tee hat eine halb taillierte Form schmeichelhaft für jede Figur. Sie können mit ins Fitnessstudio gehen Vertrauen, während es Kurven umarmt und häufiges \"Problem\" verbirgt Bereiche. Finden Sie atemberaubende Cocktailkleider für Frauen und feiern Sie Kleider.",
  "Product suggestions": "Produktvorschläge",
  "Products": "Produkte",
  "Product_s": "Produkt | Produkte",
  "Products found": "Produkte gefunden",
  "Purchase terms": "Kaufbedingungen",
  "Published": "Veröffentlicht",
  "Quality in the details": "Qualität im Detail",
  "Quantity": "Menge",
  "Quantity tip":"Mengentipp",
  "Read all reviews": "Alle Bewertungen lesen",
  "Read and understand": "Ich habe das gelesen und verstanden",
  "Read reviews": "Bewertungen lesen",
  "Register": "Registrieren",
  "Register today": "Melde dich noch heute an",
  "Remove": "Löschen",
  "Remove Address": "Adresse entfernen",
  "Remove from Wishlist": "Von Wunschliste entfernen",
  "Repeat Password": "Wiederhole das Passwort",
  "Set a new password": "Neues Passwort setzen",
  "Review my order": "Meine Bestellung überprüfen",
  "Same as shipping address": "Wie Versandadresse",
  "Save changes": "Änderungen speichern",
  "Save for later": "Für später speichern",
  "Save Password": "Passwort Speichern",
  "Search": "Suche",
  "Search for items": "Nach Artikeln suchen",
  "Search results": "Suchergebnisse",
  "Sections that interest you": "Abschnitte, die dich interessieren können.",
  "See all": "Alle anzeigen",
  "Show all": "Alle",
  "See all results": "Alle Ergebnisse anzeigen",
  "See more": "Mehr sehen",
  "Select payment method": "Zahlungsmethode auswählen",
  "Select shipping method": "Versandart auswählen",
  "Send my feedback": "Sende mein Feedback",
  "Set up newsletter": "Richte deinen Newsletter ein und wir senden dir wöchentlich Informationen zu neuen Produkten und Trends aus den von Ihnen ausgewählten Bereichen",
  "Share your look": "Teile deinen Look",
  "Shipping": "Versand",
  "Shipping address": "Lieferanschrift",
  "Shipping details": "Versanddetails",
  "Shipping method": "Versandart",
  "Show":"Anzeigen",
  "show more": "mehr anzeigen",
  "Show on page": "Auf Seite anzeigen",
  "Sign in": "Einloggen",
  "Size guide": "Größentabelle",
  "Sign Up for Newsletter": "Anmeldung für Newsletter",
  "Sort by": "Sortieren nach",
  "Sort: Relevance": "Relevanz",
  "Sort: Default": "Standard",
  "Sort: Name A-Z": "Sortieren A-Z",
  "Sort: Name Z-A": "Sortieren Z-A",
  "Sort: Price from high to low": "Preis von hoch bis niedrig",
  "Sort: Price from low to high": "Preis von niedrig bis hoch",
  "Start shopping": "Einkaufen starten",
  "Status": "Status",
  "Sprachauswahl": "Sprachauswahl",
  "Subscribe": "Abonnieren",
  "Subscribe to newsletter": "Anmeldung zum Newsletter",
  "subscribeToNewsletterModalContent": "Wenn du dich für den Newsletter angemeldet hast, erhältst du spezielle Angebote und Nachrichten von VSF per E-Mail. Wir werden deine E-Mail zu keinem Zeitpunkt an Dritte verkaufen oder weitergeben. Bitte beachte unsere {0}.",
  "Subtotal": "Zwischensumme",
  "Subtotal price": "Zwischensumme Preis",
  "Successful placed order": "Deine Bestellung war erfolgreich. Du kannst den Status deiner Bestellung mit dem Lieferstatus überprüfen. Eine Bestätigungs-E-Mail mit weiteren Informationen wird dir zugeschickt.",
  "Terms and conditions": "Allgemeine Geschäftsbedingungen",
  "Thank you": "Danke",
  "Thank You Inbox": "Wenn die Nachricht nicht in deinem Posteingang ankommt, versuche es mit einer anderen E-Mail-Adresse, mit der du dich möglicherweise registriert hast. ",
  "Total": "Gesamt",
  "Order Total": "Auftragssumme",
  "Total items": "Gesamtanzahl",
  "Total price": "Gesamtpreis",
  "Update password": "Passwort aktualisieren",
  "Update personal data": "Persönliche Daten aktualisieren",
  "Use your personal data": "Bei Markennamen legen wir großen Wert auf Datenschutzfragen und verpflichten uns, die persönlichen Daten unserer Benutzer zu schützen. Erfahre mehr darüber, wie wir deine persönlichen Daten pflegen und verwenden",
  "User Account": "Benutzerkonto",
  "View": "Ansicht",
  "View details": "Details anzeigen",
  "Vue Storefront Next": "Vue Storefront Next",
  "We haven’t found any results for given phrase": "Wir haben keine Ergebnisse für die angegebene Phrase gefunden",
  "Who we are": "Wer wir sind",
  "Wishlist": "Wunschliste",
  "Women fashion": "Damenmode",
  "You added {product} to your shopping cart.": "Du hast {product} zu deinem Warenkorb hinzugefügt.",
  "You are not authorized, please log in": "Du bist nicht autorisiert, bitte melde dich an.",
  "You can unsubscribe at any time": "Du kannst dich jederzeit abmelden",
  "You currently have no orders": "Du hast derzeit keine Bestellungen",
  "The email field must be a valid email":"Das E-Mail-Feld muss eine gültige E-Mail-Adresse sein",
  "You haven’t searched for items yet": "Du hast noch nicht nach Artikeln gesucht.",
  "Your bag is empty": "Deine Tasche ist leer",
  "Your current email address is": "Deine aktuelle E-Mail-Adresse lautet",
  "Your email": "Deine E-Mail",
  "You have submitted no reviews": "Du hast keine Bewertungen abgegeben",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"Die Kontoanmeldung war falsch oder dein Konto wurde vorübergehend deaktiviert. Bitte warte und versuche es später erneut.",
  "A customer with the same email address already exists in an associated website.": "Ein Kunde mit derselben E-Mail-Adresse existiert bereits.",
  "Invalid email": "Ungültige E-Mail",
  "SUMMER COLLECTION {year}": "SOMMERKOLLEKTION {year}",
  "Colorful summer dresses are already in store": "Bunte Sommerkleider sind bereits im Store",
  "Learn more": "Mehr erfahren",
  "Dresses": "Kleider",
  "Cocktail & Party": "Cocktailparty",
  "Linen Dresses": "Leinenkleider",
  "T-Shirts": "T-Shirts",
  "The Office Life": "Das Büroleben",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Finde atemberaubende Cocktailkleider für Damen und Cocktailkleider für Party-D…c von all deinen Lieblingsmarken.",
  "Shop now": "Jetzt einkaufen",
  "Summer Sandals": "Sommersandalen",
  "Eco Sandals": "Öko-Sandalen",
  "Subscribe to Newsletters": "Newsletter abonnieren",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Informiere dich über bevorstehende Verkäufe und Veranstaltungen. Erhalte Geschenke und Sonderangebote",
  "Fashion to take away": "Mode zum Mitnehmen",
  "Download our application to your mobile": "Lade unsere Anwendung auf dein Handy herunter",
  "Share Your Look": "Teile deinen Look",
  "My Account": "Mein Konto",
  "My profile": "Mein Profil",
  "Personal Details": "Persönliche Daten",
  "Addresses details": "Adressendetails",
  "My newsletter": "Mein Newsletter",
  "Log out": "Ausloggen",
  "My reviews": "Meine Bewertungen",
  "Order history": "Bestellverlauf",
  "Order details": "Bestelldetails",
  "My wishlist": "Meine Wunschliste",
  "Password change": "Passwortänderung",
  "Personal data": "Persönliche Daten",
  "Your e-mail": "Deine E-Mail",
  "Current Password": "Jetziges Passwort",
  "You are not authorized, please log in.": "Du bist nicht autorisiert, bitte melde dich an.",
  "Go To Product": "Zum Produkt",
  "Returns":"Rücklauf",
  "My orders": "Meine Bestellungen",
  "Add the address": "Füge deine Adresse hinzu",
  "Set as default shipping": "Als Standardversand festlegen",
  "Set as default billing": "Als Standardabrechnung festlegen",
  "HN": "Hausnr.",
  "Street Name": "Straßenname",
  "City": "Ort",
  "State/Province": "Staat/Provinz",
  "Zip-code": "Postleitzahl",
  "Country": "Land",
  "Phone number": "Telefon",
  "Please select a country first": "Wähle zuerst ein Land aus",
  "This field is required": "Dieses Feld ist erforderlich",
  "The field should have at least 2 characters": "Das Feld sollte mindestens 2 Zeichen lang sein",
  "The field should have at least 4 characters": "Das Feld sollte mindestens 4 Zeichen lang sein",
  "The field should have at least 8 characters": "Das Feld sollte mindestens 8 Zeichen lang sein",
  "New Password": "Neues Passwort",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Für dieses Land sind keine Versandarten verfügbar. Es tut uns leid, bitte versuche es mit einem anderen Land oder später.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Beim Versuch, Versandarten abzurufen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuche es mit anderen Versanddetails oder später.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Beim Versuch, diese Versandart auszuwählen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuche es mit einer anderen Versandart.",
  "We can't find products matching the selection.":"Wir können keine Produkte finden, die der Auswahl entsprechen.",
  'Page not found': 'Seite nicht gefunden',
  'Back to Home page': 'Zurück zur Startseite',
  'An error occurred': 'Ein Fehler ist aufgetreten',
  "AllProductsFromCategory": "Alle {categoryName}",
  "Show more": "Zeig mehr",
  "Show less": "Zeige weniger",
  "Yes": "Ja",
  "No": "Nein",
  "Apply filters": "Filter anwenden",
  "The coupon code isn't valid. Verify the code and try again.": "Der Gutscheincode ist ungültig. Überprüfe deinen Code und versuche es erneut.",
  "From": "Aus",
  "To": "Zu",
  "Your customization": "Deine Anpassung",
  "Passwords don't match":"Passwörter stimmen nicht überein",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number":"Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens enthalten: 1 Groß- und Kleinbuchstabe, 1 Ziffer",
  "Show all products":"Alle Produkte anzeigen",
  "Select previously saved address":"Zuvor gespeicherte Adresse auswählen",
  "Enter different address":"Gebe eine andere Adresse ein",
  "You must confirm your account. Please check your email for the confirmation link.": "Du musst dein Konto bestätigen. Bitte überprüfe deine E-Mail auf den Bestätigungslink.",
  "Change Store":"Laden Wechseln",
  "Choose Currency":"Währung wählen",
  "Add a review":"Bewertung hinzufügen",
  "Add to cart":"Zum Warenkorb",
  "Title":"Titel",
  "Name":"Name",
  "Review":"Rezension",
  "Add review":"Bewertung hinzufügen",
  "Are you sure you would like to remove this item from the shopping cart?":"Bist du sicher, dass du diesen Artikel aus dem Warenkorb entfernen möchten?",
  "Your cart is empty":"Dein Warenkorb ist leer",
  "Are you sure?":"Bist du dir sicher?",
  "{0} has been successfully removed from your cart":"{0} wurde erfolgreich aus deinem Warenkorb entfernt",
  "Amount":"Menge",
  "Discount from":"Rabatt ab",
  "quantity discount from":"Mengenrabatt ab",
  "pieces": "Stück",
  "Thank you for your order!":"Vielen Dank für deinen Auftrag!",
  "Your Purchase":"Dein Einkauf",
  "Primary contacts for any questions":"Primäre Ansprechpartner für alle Fragen",
  "Contact Person": "Ansprechpartner/-in",
  "Your Account":"Dein Konto",
  "What can we improve":"Was können wir verbessern",
  "Payment date":"Zahlungsdatum",
  "The user password was changed successfully updated!":"Das Benutzerpasswort wurde erfolgreich geändert aktualisiert!",
  "The user account data was successfully updated!":"Die Benutzerkontodaten wurden erfolgreich aktualisiert!",
  "Share": "Teilen",
  "Link wurde in die Zwischenablage kopiert":"Link wurde in die Zwischenablage kopiert",
  "Secure checkout with SLL":"Daten sicher durch SSL Verschlüsselung",
  "Accepted payment methods":"Akzeptierte Zahlungsmethoden",
  "Total Discounts":"Gesamtrabatt",
  "Free shipping from {value},-€ and for subscription deliveries within the EU, different shipping rates apply for foreign deliveries.": "Versandkostenfrei ab {value},-€ und für Abolieferungen innerhalb der EU, restliches Ausland <a href=\"/de/services/zahlung-lieferung/\" target=\"_blank\">abweichend</a>.",
  "Enjoy complimentary free shipping if above CHF {value}":"Kostenloser Versand ab einem Bestellwert über CHF {value}",
  "<h6>Only <b> " : "<h6>Nur noch <b>",
  " € </b> until free shipping!</h6>":" € </b> bis zum Gratisversand!</h6>",
  " CHF </b> until free shipping!</h6>": " CHF </b> bis zum Gratisversand!</h6>",
  "FREE":"KOSTENFREI",
  "free":"kostenfrei",
  "Add promotional code:":"Gutscheincode einlösen:",
  "Remove Item from basket":"Aus Warenkorb entfernen",
  "-10% Subcription Discount":"-10% Abo Rabatt",
  "Subcription discount":"Abo Rabatt",
  "12 month subscription":"12 mtl. Abonnement",
  "Subscription":"Abonnement",
  "Subscription:":"Abonnement:",
  "Content":"Inhalt",
  "Volume":"Volumen",
  "Order type":"Bestellart",
  "Product Details":"Produkt Details",
  "including VAT,":"inkl. MwSt.",
  "excluding shipping":"zzgl. Versand",
  "Product and therapy news": "Produkt- und Therapieneuigkeiten",
  "Further training offers": "Fortbildungsangebote",
  "Other health topics (e.g. from Provicell)": "weitere Gesundheitsthemen (z. B. von Provicell)",
  "I would like to receive newsletters on the following topics": "Ich möchte Newsletter zu folgenden Themen erhalten",
  "Product available": "Produkt verfügbar",
  "Product not available": "Produkt nicht verfügbar",
  "Read more": "Mehr erfahren",
  "Read time": "Lesezeit",
  "Total Purchase":"Gesamtbetrag",
  "Promotional code":"Gutscheincode",
  "Continue to checkout":"Weiter mit Checkout",
  "Duration": "Dauer",
  "Location": "Ort",
  "Speakers": "Referent/-in",
  "Type of events": "Art des Events",
  "Apply Filter" : "Filtern",
  "Reset": "Zurücksetzen",
  "View all training courses" : "Alle Fortbildungen anzeigen",
  "Show less training" : "Weniger Fortbildungen anzeigen",
  "Referent" : "Referent/-in",
  "Load more events" : "Weitere Events laden",
  "Target group" : "Zielgruppe",
  "Sign-up for event" : "Zum Event anmelden",
  "Add to Cart":"In den Warenkorb",
  "Load more trainings" : "Mehr Fortbildungen zeigen",
  "Load more" : "Mehr laden",
  "Delivery 1-3 days":"Lieferung in 1-3 Tagen",
  "Save extra 5% from 5 items":"Spare zusätzliche 5% bei 5 Artikeln",
  "Cancel anytime with no extra fees":"Beenden dauerhaft möglich ohne extra Kosten",
  "Best option for ongoing intake":"Ideale Lösung für kontinuierliche Einnahme",
  "No SKU Found!":"Es konnte keine Artikelnummer festgestellt werden!",
  "Product Data issue!":"Produktdaten konnten nicht zugeordnet werden!",
  "One-time Delivery":"Einmallieferung",
  "Delivery interval":"Lieferintervall",
  "Ongoing subscription":"Spar-Abo",
  "To finish setting up your account, please, fill in the following information.":"Bitte fülle die folgenden Felder aus um die Einrichtung deines Accounts zu beenden.",
  "Your shopping cart":"Dein Warenkorb",
  "Shipping calculated on checkout":"Versandkosten berechnet im Checkout",
  "One time delivery":"Einmalige Lieferung",
  "Keep Shopping":"Weiter einkaufen",
  "Go to Cart":"Zum Warenkorb",
  "Remove Code":"Code entfernen",
  "Apply Code":"Code hinzufügen",
  "Switch to Subscription for discount":"Wechsel zum Abomodell, um zu sparen",
  "Switch to the":"Wechsel hier zum",
  "savings subscription":"Spar-Abo",
  "Savings subscription":"Spar-Abo",
  " here!":"!",
  "• save {percentage}% <br>• free shipping within the EU<br>• cancelable at any time<br>• regular automatic delivery":"• {percentage}% sparen<br>• versandkostenfrei innerhalb der EU<br>• jederzeit kündbar<br>• regelmäßige monatliche Lieferung",
  "• save {percentage}% <br>• free shipping within the EU<br>• cancelable at any time":"• {percentage}% sparen<br>• versandkostenfrei innerhalb der EU<br>• jederzeit kündbar",
  "<b>Savings subscription</b>":"<b>Spar-Abo</b>",
  "Phone number (We will only call you if there are problems with the order.)":"Telefonnummer (Wir rufen dich nur an, wenn es Probleme mit der Bestellung gibt.)",
  "We will only call you if there are problems with the order.":"Wir rufen dich nur an, wenn es Probleme mit der Bestellung gibt.",
  "Check out without an account":"Ohne Kundenkonto fortfahren",
  "Continue as a guest":"Als Gast bestellen",
  "Continue":"Weiter",
  "Next":"Weiter",
  "Update Cart":"Warenkorb aktualisieren",
  "New to TISSO?":"Neu bei TISSO?",
  "Are you new at TISSO?":"Neu bei TISSO?",
  "Create an account in just a few steps":"Erstelle dir in nur wenigen Schritten dein persönliches Kundenkonto.",
  "Regular insights via newsletter":"Daten nur einmal eingeben und beim nächsten Einkauf schneller bestellen",
  "Access to exclusive e-learning":"alle Bestellungen im Überblick ",
  "All necessary medical documentation":"bisherige Bestellungen einfach wiederholen",
  "Create account":"Kundenkonto erstellen",
  "Questions about your order?":"Hast du Fragen zu deiner Bestellung?",
  "Contact our customer service team:":"Unser Kundenservice ist gerne persönlich für dich da!",
  "Monday to Friday from 9am-12am and 1pm-4pm":"Du erreichst uns montags bis freitags von 09:00 bis 17:00 Uhr telefonisch unter",
  "How would you like to checkout?":"Wie möchtest du deine Bestellung abschließen?",
  "Welcome!":"Willkommen!",
  "Already a part of TISSO?":"Du hast bereits ein TISSO-Kundenkonto?",
  "Log in for a faster checkout":"Anmelden und Zeit sparen!",
  "Forgot your password?": "Passwort vergessen?",
  "Email address *":"E-Mail Adresse *",
  "name.surname@email.com":"name.nachname@email.de",
  "Payment options":"Zahlungsmethoden",
  "Please select a payment option to continue":"Bitte wähle eine Zahlungsmethode",
  "Purchase summary":"Bestellübersicht",
  "Verify and confirm your purchase":"Bitte überprüfe und bestätige deine Bestellung",
  "All transactions are secure and encrypted":"Alle Transaktionen sind sicher und verschlüsselt",
  "Delivery info":"Lieferinformationen",
  "Estimated delivery in 2 days":"Voraussichtliche Lieferung in 2 Tagen",
  "Continue as Guest":"Als Gast fortfahren",
  "Email is not available. You could":"Die E-Mail ist nicht verfügbar. Sie könnten",
  "log in":"einloggen",
  "with this email or use another email address.":"mit dieser E-Mail oder eine andere E-Mail-Adresse verwenden.",
  "Leave a comment (Optional)":"Dein Kommentar (optional)",
  "Type in your comment":"Schreibe einen Kommentar",
  "Your purchase":"Deine Bestellung",
  "Kapsel": "Kapsel",
  "Kapseln": "Kapseln",
  "Pulver": "Pulver",
  "Tabletten": "Tabletten",
  "Tropfen": "Tropfen",
  "Liquid": "Flüssig",
  "Bottle": "Flasche",
  "Start setting up your account by adding the following details":"Erstelle einen Account mit folgenden Eingabefeldern",
  "Create password *":"Passwort erstellen *",
  "Confirm password *":"Passwort wiederholen *",
  "Repeat password *":"Passwort wiederholen *",
  "It`s nice to have you back.":"Es ist schön dich wiederzusehen",
  "Login to your account":"Melde dich an",
  "Gender":"Anrede",
  "How did you hear about TISSO": "TISSO wurde mir empfohlen von:",
  "I am a company" : "Ich bin ein Unternehmen",
  "Street name and house number" : "Straße und Hausnummer",
  "Additional address information": "Adresszusatz",
  "Add a different Shipping address" : "Ich möchte eine abweichende Versandadresse angeben",
  "Products to be purchased": "Deine Produkte",
  "Company name": "Name des Unternehmens",
  "Please set a new password": "Bitte setze ein neues Passwort",
  "Your Title (optional)": "Dein Titel (optional)",
  "Male": "Herr",
  "Female": "Frau",
  "Not specified": "Keine Angabe",
  "Type in your message": "Gib deine Nachricht ein",
  "Choose a country": "Wähle ein Land",
  "Personal information": "Persönliche Informationen",
  "Country Shop": "Ländershop",
  "Language": "Sprache",
  "excl. VAT": "exkl. MwSt.",
  "plus Shipping":"zzgl. Versand",
  "incl. VAT":"inkl. MwSt",
  "Switzerland" : "Schweiz",
  "German" : "Deutsch",
  "de" : "Deutsch",
  "Deutsch" : "Deutsch",
  "Mineralstoffe" : "Mineralstoffe",
  "Vitamine" : "Vitamine",
  "Neuheiten" : "Neuheiten",
  "Darm und Verdauung" : "Darm und Verdauung",
  "Energiestoffwechsel" : "Energiestoffwechsel",
  "Coenzym Q10" : "Coenzym Q10",
  "Immunsystem und Abwehrkräfte" : "Immunsystem und Abwehrkräfte",
  "Blog" : "Blog",
  "Podcast" : "Podcast",
  "Bestseller" : "Bestseller",
  "en" : "Englisch",
  "Reset Password" : "Passwort zurücksetzen",
  "Regular quarterly delivery. Postage paid within Germany. Respectively 3 products every 3 months. Quarterly invoices are enclosed with the deliveries." : "Regelmäßige Lieferung quartalsweise. Portofrei innerhalb Deutschlands. Alle 3 Monate jeweils 3 Produkte. Quartalsrechnungen liegen den Lieferungen bei.",
  "Regular monthly delivery. Postage paid within Germany. Every month 1 product with your desired quantity. Monthly invoices are enclosed with the deliveries." : "Regelmäßige Lieferung monatlich. Portofrei innerhalb Deutschlands. Jeden Monat jeweils 1 Produkt mit deiner gewünschten Menge. Monatsrechnungen liegen den Lieferungen bei.",
  "With the conclusion of the subscription, the product you ordered will be delivered to you monthly. The subscription extends automatically and can be cancelled at any time without a notice period." : "Mit Abschluss des Abonnements wird Ihnen das bestellte Produkt fortlaufend monatlich geliefert. Das Abonnement verlängert sich automatisch und kann jederzeit ohne Kündigungsfrist abbestellt werden.",
  "Possible discount":"Mengenrabatt",
  "Bundle discount":"Mengenrabatt",
  "Add":"Füge",
  "or more to get":"weitere hinzu für",
  "off":"Rabatt",
  "Account Holder Name":"Kontoinhaber",
  "Swift Code":"BIC",
  "Policy Text": {
    "policyText1":"Ich ermächtige die TISSO Naturprodukte GmbH, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der TISSO Naturprodukte GmbH auf mein Konto gezogenen Lastschriften einzulösen.",
    "policyText2":"Hinweis: Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belastenden Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.",
    "policyText3":"Weitere Informationen wie Mandatsreferenz und den genauen Einzugstermin des RE-Betrages kannst du der Rechnung entnehmen.",
  },
  "EU" : "EU",
  "End" : "Ende",
  "Start" : "Beginn",
  "Pages" : "Seiten",
  "No results found for" : "Keine Ergebnisse gefunden für",
  "Search results for" : "Suchergebnisse für",
  "Showing all results for" : "Alle Ergebnisse anzeigen für",
  "Search for products, services, topics..." : "Suche nach Produkten, Anwendungsbereichen, ...",
  "Events" : "Veranstaltungen",
  "select one" : "Wähle eins",
  "Live Stream" : "Liveübertragung",
  "No Event Found" : "Keine Veranstaltung gefunden",
  "Application Areas" : "Anwendungsbereiche",
  "Update the address" : "Adresse akualisieren",
  "Additional address line": "Adresszusatz",
  "Estimated delivery in 1-4 days": "Voraussichtliche Lieferung in 1-4 Tage",
  "Estimated delivery in 2-6 days": "Voraussichtliche Lieferung in 2-6 Tage",
  "Shipping fee": "Versandkosten",
  "Unfortunately, there are currently no planned English events in this category": "Leider findet aktuell keine Veranstaltung dieser Art statt",
  "House/Apartment number": "Adresszusatz",
  "Popular Searches" : "Beliebte Suchanfragen",
  "Close": "Close",
  "English": "English",
  'capsules' : 'Kapseln',
  'tablets' : 'Tabletten',
  'drops' : 'Tropfen',
  'powder' : 'Pulver',
  'liquid' : 'Flüssigkeit',
  'other' : 'other',
  "Flasche": "Flasche",
  "Your order summary": "Zusammenfassung deiner Bestellung",
  "For orders from Switzerland please use our Swiss Shop. Please click OK to be redirected there.": "Für Bestellungen aus der Schweiz nutze bitte unseren Schweizer Shop. Klicke bitte auf OK um dahin umgeleitet zu werden.",
  "For orders from EU please use our EU Shop. Please click OK to be redirected there.": "Für Bestellungen aus der EU nutze bitte unseren EU Shop. Klicke bitte auf OK um dahin umgeleitet zu werden.",
  "You'll find all payment information within your order confirmation mail.": "Alle Zahlungsinformationen schicken wir dir in der Bestellbestätigungs-Mail.",
  "Value not matched": "Werte stimmen nicht überein",
  "Partner area": "Partner-Bereich",
  "Partner login": "Partner-Login",
  "For subscription orders only for the first payment. Then on account.": "Bei Abos nur für die erste Zahlung, danach auf Rechnung.",
  "Trustpilot consent": "Ich möchte den Einkauf später bewerten und einmalig per E-Mail an die Abgabe einer Bewertung für die Bewertungsplattform Trustpilot erinnert werden. Meine Einwilligung kann ich jederzeit widerrufen.",
  "monatliche Lieferung":"monatliche Lieferung",
  "quartalsweise Lieferung":"quartalsweise Lieferung",
  "Product removed from cart":"Produkt aus dem Warenkorb entfernt",
  "Loading podcasts...":"Laden von Podcasts...",
  "No podcasts available":"Keine Podcasts verfügbar",
  "OUR RECOMMENDATION FOR YOU":"UNSERE EMPFEHLUNG FÜR DICH",
  "Our recommendation to you":"Unsere Empfehlung für dich",
  "An account already exists for your email address.":"Zu deiner E-Mail-Adresse existiert bereits ein Konto.",
  "Would you like to log in with this?":"Möchtest du dich damit anmelden?",
  "proceed as guest":"als Gast fortfahren",
  "We are taking a company vacation! Orders placed between 22.12.23 and 01.01.24 will be processed as soon as possible from 02.01.2024.":"Wir machen Betriebsferien! Bestellungen zwischen dem 22.12.23 und 01.01.24 bearbeiten wir schnellstmöglich ab dem 02.01.2024.",
  "Please note:":"Bitte beachte:",
  "We're taking a few days off.":"Wir gönnen uns ein paar Tage Pause.",
  "Orders placed between 22.12.23 and 01.01.24 will be processed as soon as possible from 02.01.2024.":"Bestellungen zwischen dem 22.12.23 und 01.01.24 bearbeiten wir schnellstmöglich wieder ab dem 02.01.2024.",
  "Often bought together with": "Wird oft zusammen gekauft mit",
  "Proceed to cart": "Weiter zum Warenkorb",
  "was successfully added": "wurde erfolgreich hinzugefügt",
  "Monthly delivery": "Monatliche Lieferung",
  "Free shipping within the EU": "Versandkostenfrei innerhalb der EU Sofort",
  "Available immediately, can be cancelled at any time": "Sofort verfügbar, jederzeit kündbar",
  "Save": "Spare",
  "Monthly savings subscription": "Monatliches Spar-Abo",
  "Proceed to checkout": "Jetzt sicher zur Kasse",
  "Company Holidays at TISSO": "Betriebsferien bei TISSO",
  "Orders placed between December 20, 2024, and January 2, 2025, will be processed as soon as possible beginning on January 3, 2025.": "Bestellungen zwischen dem 20.12.2024 und dem 02.01.2025 bearbeiten wir schnellstmöglich wieder ab dem 03.01.2025.",
};
